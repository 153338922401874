
export const AppMenus = {

  navbarTopRight: [],
  navbarTopLeft: [],
  navbarSideLeft: [
    {
      "to": "/home",
      "label": "Home",
      "icon": "pi pi-home",
      "iconcolor": "",
      "target": "",

    },
    {
      "to": "/management",
      "label": "Managements",
      "icon": "pi pi-users",
      "iconcolor": "",
      "target": "",

    },
    {
      "to": "/users",
      "label": "Members",
      "icon": "pi pi-users",
      "iconcolor": "",
      "target": "",

    },
    {
      "to": "/events",
      "label": "Contents",
      "icon": "pi pi-comments",
      "iconcolor": "",
      "target": "",

    },
    {
      "to": "/comments",
      "label": "Comments",
      "icon": "pi pi-comment",
      "iconcolor": "",
      "target": "",
    },
    {
      "to": "/supporttickets",
      "label": "Support Tickets",
      "icon": "pi pi-eye",
      "iconcolor": "",
      "target": "",

    },
    {
      "to": "/feedbacks",
      "label": "Feedbacks",
      "icon": "pi pi-users",
      "iconcolor": "",
      "target": "",
    },
    // {
    //   "to": "/messages",
    //   "label": "Messages",
    //   "icon": "pi pi-envelope",
    //   "iconcolor": "",
    //   "target": "",
    // },


  ],
  type: [
    { value: "Post", label: "Post" },
    { value: "Video", label: "Video" }
  ],
  category: [
    { value: "General", label: "General" },
    { value: "Presidency", label: "Presidency" },
    { value: "Wallpaper", label: "Wallpaper" },
    { value: "Car Sticker", label: "Car Stickers" },
    { value: "T-Shirt Design", label: "T-Shirt Designs" },
    { value: "Autographed T-Shirts", label: "Autographed T-Shirts" },
    { value: "Phones and Laptop", label: "Phones and Laptop" },
    { value: "Picture DMB and Samira", label: "Picture DMB and Samira" },
  ],
  gender: [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" }
  ],

  exportFormats: {
    print: {
      label: 'Print',
      icon: 'pi pi-print',
      type: 'print',
      ext: 'print',
    },
    pdf: {
      label: 'Pdf',

      icon: 'pi pi-file-pdf',
      type: 'pdf',
      ext: 'pdf',
    },
    excel: {
      label: 'Excel',
      icon: 'pi pi-file-excel',
      type: 'excel',
      ext: 'xlsx',
    },
    csv: {
      label: 'Csv',
      icon: 'pi pi-table',
      type: 'csv',
      ext: 'csv',
    },
  },

}